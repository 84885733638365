import React from "react";
import Typed from "react-typed";
import Link from "next/link";
import { FiArrowRight } from "react-icons/fi";
import Head from "next/head";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";

export default function BusinessConsulting2() {
  return (
    <main className="page-wrapper">
      <Head>
        <title>Modulabs</title>
      </Head>
      <Header className="header-not-transparent" />
      {/* Start Slider Area  */}
      <div className="slider-area slider-style-1 bg-transparent height-650">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <span className="subtitle">SOFTWARE DEVELOPMENT AGENCY</span>
                <h1 className="title theme-gradient display-two">
                  <Typed
                    strings={["Mobile Apps", "Web Apps", "Backend Services"]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                  <br />
                  Built For You
                </h1>
                <p className="description">
                  We help our clients succeed by creating brand identities,
                  digital experiences, and print materials.
                </p>
                <div className="button-group">
                  <Link
                    className="btn-default btn-medium btn-border round btn-icon"
                    href="/contact-us"
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>
                      Contact Us
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Slider Area  */}

      <Separator />

      {/* Start About Area  */}
      {/* <About /> */}
      {/* End About Area  */}

      {/* <Separator /> */}
      {/* Start Service Area  */}
      <div className="rn-service-area rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="What we can do for you"
                title="Web & Mobile App Development."
                description={
                  <>
                    Modulabs is a technology solution provider specialized in
                    Web and Mobile App Development. Our company focuses on
                    building custom applications that meet our customers&rsquo;
                    needs, without the need for customizations in future
                    versions. Our development team has extensive experience in
                    developing enterprise-grade applications using modern tools
                    and technologies.
                  </>
                }
              />
            </div>
          </div>
          {/* <ServiceFive serviceStyle="gallery-style" textAlign="text-start" /> */}
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start Call To Action Area  */}
      <div className="rwt-callto-action-area">
        <div className="wrapper">{/* <CalltoActionFive /> */}</div>
      </div>
      {/* End Call To Action Area  */}

      <Separator />
      {/* Start Mission Area   */}
      <Footer />
    </main>
  );
}
